// src/components/Footer.js
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FaGithub, FaInstagram, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center py-3 mt-auto">
      <Container>
        <Row>
          
        </Row>
        <Row>
        <Col
            xs={12}
            md={4}
            className="text-center text-md-right"
            style={{ paddingTop: 10, display: "flex", justifyContent: "center" }}
          >
            <a
              href="https://rmit.edu.au/ciairi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src="./images/CIAIRI.png"
                style={{ height: 45 }}
                className="d-inline-block align-top"
              />
            </a>
          </Col>
          
          <Col xs={12} md={4} style={{ paddingTop: 10, paddingBottom:10}}>
            <div className="footer-social-links">
              <a
                href="https://github.com/rmit-computing-technologies/redbackbots-coderelease"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-link"
              >
                <FaGithub size={30} />
              </a>
              <a
                href="https://www.instagram.com/redbackbots"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-link"
              >
                <FaInstagram size={30} />
              </a>
              {/* <a href="https://www.tiktok.com/@your-tiktok-profile" target="_blank" rel="noopener noreferrer" className="footer-social-link">
                <FaTiktok size={30} />
              </a> */}
              <a
                href="https://www.youtube.com/@RMITRedbackBots"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-link"
              >
                <FaYoutube size={30} />
              </a>
            </div>
          </Col>
          <Col
            xs={12}
            md={4}
            className="text-center text-md-left"
            style={{ paddingTop: 10, display: "flex", justifyContent: "center" }}
          >
            <p className="footer-copy" style={{ margin: "auto 0" }}>
              &copy; 2024 RMIT RedbackBots
            </p>
          </Col>
        </Row>

        <Row></Row>
      </Container>
    </footer>
  );
};

export default Footer;
