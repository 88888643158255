import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./pages/About";
import Sponsorships from "./pages/Sponsorships";
import Links from "./pages/Links";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "./App.css";
import { Join } from "./pages/Join";
import { Stack } from "react-bootstrap";
import Privacy from "./pages/Privacy";

function App() {
  // https://schema.org/Article
  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    image: "https://redbackbots.com/images/RedbacksLogo.png",
    url: "https://redbackbots.com",
    logo: "https://redbackbots.com/images/RedbacksLogo.png",
    name: "RMIT RedbackBots",
    description: "RMIT's Robot Soccer Team",
    email: "STEM.RedbackBots@rmit.edu.au",
    foundingDate: "2020-01-01",
  };
  return (
    <Router>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(articleStructuredData),
        }}
      />
      <Stack style={{ minHeight: "100vh", backgroundColor: "black" }}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index.html" element={<Home />} />
          <Route path="links" element={<Links />} />
          <Route path="/sponsorships" element={<Sponsorships />} />
          <Route path="/about" element={<About />} />
          <Route path="/join" element={<Join />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <Footer />
      </Stack>
    </Router>
  );
}

export default App;
