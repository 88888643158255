import React from "react";
import { Container } from "react-bootstrap";
import { PageTitle } from "../components/PageTitle";

export default function Privacy() {
  return (
    <Container className="page-container">
      <PageTitle title={"GameSight Privacy Policy"} />
      <Container>
        <h5>
          No data is retrieved, stored, or retained other than what the Meta Quest
          automatically collects. Contact
          STEM.redbackbots@rmit.edu.au. with any questions or request for data privacy
          and removal (no collected data to remove, this is a checkbox item in
          the app submission to App Lab)
        </h5>
      </Container>
    </Container>
  );
}
