// src/components/Header.js
import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "./NavLink";

const Header = () => {
  const location = useLocation();

  return (
    <Navbar bg="dark" variant="dark" expand="md" sticky="top">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            alt="RMIT Redbackbots Logo"
            src="/images/RedbacksLogo.png" // Adjust the path based on your folder structure
            width="128"
            height="51"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            <NavLink shortUrl={""} displayText={"Home"} location={location} />
            <NavLink
              shortUrl={"sponsorships"}
              displayText={"Sponsorships"}
              location={location}
            />
            <NavLink
              shortUrl={"about"}
              displayText={"About Us"}
              location={location}
            />
            <NavLink
              shortUrl={"join"}
              displayText={"Join the Team"}
              location={location}
            />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
