import {Container, Stack } from "react-bootstrap";

export default function Links() {
  return (
    <Container className="page-container">
      <Stack gap={1}>
        <a href="join">Join the team</a>
        <a href="https://www.instagram.com/redbackbots">Instagram</a>
      </Stack>
    </Container>
  );
}
