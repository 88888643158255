import React from "react";
import { Container, Carousel, Stack } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles for the image carousel
import { PageTitle } from "../components/PageTitle";

const Home = () => {
  return (
    <Container className="page-container">
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/2023-team-bordeaux.jpg"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src="/images/robot-on-field.jpg" alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/robots-on-field.jpg"
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
        <Stack gap={3}>
        <PageTitle title={"We are the RMIT RedbackBots"} />
        <Container>
          <h4>
            A relatively new, but dedicated team of RMIT students who program autonomous
            robots to play soccer.
          </h4>
        </Container>
        </Stack>
    </Container>
  );
};

export default Home;
