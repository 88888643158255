import React from "react";
import { Container } from "react-bootstrap";

export function PageTitle({ title }) {
  return (
    <>
      <Container className="pt-3">
        <h2>{title}</h2>
      </Container>
    </>
  );
}
